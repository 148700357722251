// @flow
import { NoIndexMetaTag, PageContent, SEO } from "@components";
import { Analytics } from "@containers";
import { Constants } from "@utils";
import { StorageContext } from "@utils/BrowserStorage/StorageContext";
import { CategoryBarContext } from "@utils/Category/CategoryBarContext";
import { PAGE_TYPES } from "@utils/Constants";
import { LocaleContext } from "@utils/Localization/LocaleContext";
import withURLParameters from "@utils/ParametersHOC";
import { graphql } from "gatsby";
import React from "react";

import {
    contentfulTypenames,
    ContentModelMapper,
    determineStyle,
} from "../utils";
import { generateBreadcrumb } from "../utils/Breadcrumb";
import { LEAD_SCORING_DEFAULT_VALUES } from "../utils/Constants";
import { PageContext } from "../utils/DynamicArticles";
import { loadLocalStorageId } from "../utils/LocalStorage";
import { getHeroVariant } from "../utils/Variants";

const PageLandingTemplate = ({
    data: {
        page,
        channel,
        market,
        navigation,
        footer,
        site: {
            siteMetadata: { siteUrl },
        },
    },
    query,
    location,
}: *) => {
    let breadcrumb = null;

    const hasCategoryBar = page?.content
        ?.map(({ __typename }) => __typename)
        ?.includes(contentfulTypenames.categoryBar);

    if (!hasCategoryBar && !page.hideBreadcrumbs)
        breadcrumb = {
            path: generateBreadcrumb(page, market),
            theme: page?.breadcrumbBackgroundColor,
        };

    const navData = determineStyle(
        page?.navigationRendering,
        page.navigation || navigation,
    );

    const hero = getHeroVariant(page);

    return (
        <CategoryBarContext.Provider
            value={
                page.content?.length > 0 &&
                page.content.some(
                    entry =>
                        entry.__typename === contentfulTypenames.categoryBar,
                )
            }
        >
            <LocaleContext.Provider
                value={{
                    language: page.node_locale,
                    market,
                }}
            >
                <PageContext.Provider value={page}>
                    <StorageContext.Provider
                        value={loadLocalStorageId(Constants.QUERY_PARAMETERS)}
                    >
                        <Analytics.PageWrapper
                            key={page.id}
                            entry={page.id}
                            type={PAGE_TYPES.LANDING}
                            title={page.title}
                            language={page.node_locale}
                            urlParameters={query}
                            slug={page.slug}
                            segments={page.segments}
                            seoKeywords={page.seoKeywords}
                            leadScoringValue={
                                page.leadScoringValue ||
                                LEAD_SCORING_DEFAULT_VALUES[PAGE_TYPES.LANDING]
                            }
                            tags={page.metadata.tags}
                        >
                            <SEO
                                title={page.title}
                                translations={page.translations}
                                publishedMarkets={page.markets}
                                metaDescription={page.metaDescription}
                                siteUrl={siteUrl}
                                currentPath={location.pathname}
                                page={page}
                                market={market}
                                linkedPage={page.relatedContent}
                                linkedTranslations={page.linkedPageTranslations}
                            />
                            <NoIndexMetaTag page={page} channel={channel} />
                            <PageContent
                                entry={page}
                                translations={page.translations}
                                relatedContent={page.relatedContent}
                                slug={page.slug}
                                publishedMarkets={page.markets}
                                enableScrollTop={page.enableScrollTop}
                                hideCategoryBar
                                hideCategoryBarMobile
                                header={
                                    hero &&
                                    hero.__typename ===
                                        contentfulTypenames.variantContainer
                                        ? ContentModelMapper.mapVariantContainer(
                                              hero,
                                              {
                                                  withBottomMargin: false,
                                                  noMargin: true,
                                              },
                                          )
                                        : ContentModelMapper.map(hero, {
                                              withBottomMargin: false,
                                              noMargin: true,
                                          })
                                }
                                breadcrumb={breadcrumb}
                                footer={page.footer || footer}
                                navigation={navData}
                                offer={page.offer}
                                disclaimer={page.disclaimer}
                                blogHomepage={page.blogHomepage}
                                dossier={page.dossier}
                                allDossiersSlug={page.allDossiersSlug}
                                disablePip={page.disablePip}
                            />
                        </Analytics.PageWrapper>
                    </StorageContext.Provider>
                </PageContext.Provider>
            </LocaleContext.Provider>
        </CategoryBarContext.Provider>
    );
};

export default withURLParameters(PageLandingTemplate);

export const query = graphql`
    query(
        $id: String!
        $language: String!
        $marketCode: String!
        $channel: String!
        $categoryId: String = null
    ) {
        ...SiteMetadata
        page: contentfulPageLanding(
            contentful_id: { eq: $id }
            node_locale: { eq: $language }
        ) {
            id
            contentful_id
            __typename
            title
            slug
            navigationRendering
            node_locale
            metaDescription
            seoKeywords
            leadScoringValue
            breadcrumb
            breadcrumbBackgroundColor
            metadata {
                tags {
                    name
                }
            }
            relatedContent {
                node_locale
                slug
                markets {
                    ...MarketGeneral
                }
                linkedPageTranslations {
                    slug
                    node_locale
                }
                translations {
                    slug
                    node_locale
                }
            }
            breadcrumbPath {
                ...BreadcrumbRecursive
            }
            hero: heroMarketSpecific(marketCode: $marketCode) {
                ...DisplayHero
            }
            heroVariant: hero {
                ...VariantContainer
            }
            introduction {
                ...Introduction
                ...VariantContainer
            }
            translations {
                slug
                node_locale
            }
            linkedPageTranslations {
                slug
                node_locale
            }
            markets {
                ...MarketGeneral
            }
            content {
                ...DisplaySection
                ...ContentSelector
                ...Content
                ...Podcast
                ...VariantContainer
                ...DisplayExpandableCard
                ...DisplayTabContainer
            }
            navigation {
                ...Navigation
            }
            disablePip
            displaySticky {
                ...DisplaySticky
                ...VariantContainer
            }
            footer {
                ...Footer
            }
            offer(locale: $language, marketCode: $marketCode) {
                ...PromotionalOffer
            }
            enableScrollTop
            showLastUpdated
            updatedAt(formatString: "DD.MM.YYYY")
            segments
            noIndex
            hideBreadcrumbs
            disclaimer {
                ...Disclaimer
            }
            blogHomepage
            allDossiersSlug
            dossier(marketCode: $marketCode) {
                node_locale
                slug
                title
                pageHeader {
                    ... on ContentfulDisplayHeader {
                        __typename
                        description {
                            raw
                            references {
                                __typename
                                ... on ContentfulPage {
                                    contentful_id
                                    slug
                                    channels {
                                        channel
                                    }
                                }
                                ... on ContentfulPageLanding {
                                    contentful_id
                                    slug
                                    channels {
                                        channel
                                    }
                                }
                                ... on ContentfulPageExperiencePage {
                                    contentful_id
                                    slug
                                    channels {
                                        channel
                                    }
                                }
                            }
                        }
                    }
                    ... on ContentfulDisplayHero {
                        __typename
                        contentful_id
                        rtTitle {
                            raw
                        }
                        tagline
                    }
                }
                entry {
                    ... on ContentfulDossier {
                        ...DossierGeneral
                        editions: dossierEditions(marketCode: $marketCode) {
                            __typename
                            articles: marketSpecificArticles(
                                marketCode: $marketCode
                                language: $language
                            )
                            experts(
                                marketCode: $marketCode
                                language: $language
                            )
                        }
                    }
                }
            }
            navigationinpanellink {
                ... on ContentfulNavigationItem {
                    location {
                        ... on ContentfulCallToAction {
                            title: name
                            externalLink
                            internalLink {
                                ... on ContentfulPage {
                                    title
                                    slug
                                    __typename
                                }
                                ... on ContentfulPageLanding {
                                    title
                                    slug
                                    __typename
                                }
                                ... on ContentfulPageHome {
                                    title
                                    __typename
                                }
                                ... on ContentfulPageExperiencePage {
                                    title
                                    slug
                                    __typename
                                }
                                ... on ContentfulDocumentVersion {
                                    name
                                    slug
                                    __typename
                                }
                            }
                            requestParameters
                            __typename
                        }
                        ... on ContentfulPage {
                            title
                            slug
                            __typename
                        }
                        ... on ContentfulPageLanding {
                            title
                            slug
                            __typename
                        }
                    }
                }
            }
        }
        channel: contentfulChannel(channel: { eq: $channel }) {
            noIndex
        }
        market: contentfulMarket(
            code: { eq: $marketCode }
            node_locale: { eq: $language }
        ) {
            ...MarketGeneral
        }
        navigation: contentfulNavigation(
            node_locale: { eq: $language }
            markets: { elemMatch: { code: { eq: $marketCode } } }
            channels: { elemMatch: { channel: { eq: $channel } } }
            default: { eq: true }
        ) {
            ...Navigation
        }

        footer: contentfulFooter(
            node_locale: { eq: $language }
            markets: { elemMatch: { code: { eq: $marketCode } } }
            channels: { elemMatch: { channel: { eq: $channel } } }
            default: { eq: true }
        ) {
            ...Footer
        }
    }
    fragment PageLandingHeroImageQuery on ContentfulImageBlock {
        focalPoint
        image {
            fields {
                ensured {
                    gatsbyImageData(
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        formats: [WEBP]
                        quality: 65
                        breakpoints: [576, 768, 1024, 1366]
                    )
                    title
                    file {
                        url
                    }
                }
            }
        }
    }

    fragment DisplayHero on ContentfulDisplayHero {
        __typename
        contentful_id
        label
        tagline
        motionLoop
        backgroundDesktopMotion {
            fields {
                ensured {
                    description
                    title
                    file {
                        url
                        fileName
                        contentType
                    }
                }
            }
        }
        backgroundMobileMotion {
            fields {
                ensured {
                    description
                    title
                    file {
                        url
                        fileName
                        contentType
                    }
                }
            }
        }
        asset {
            ... on ContentfulImageBlock {
                ...PageLandingHeroImageQuery
            }
            ... on ContentfulVideoBlock {
                ...VideoBlock
            }
            ...MarketSpecificAsset
        }
        rtTitle {
            raw
        }
        heroSize
        heroLinksTitle
        scrollDownCopy
        showScrollDownOption
        callToAction {
            ...CallToAction
        }
        heroLinks {
            ... on ContentfulNavigationItem {
                subnavItemName: navigationItemName
                location {
                    ... on ContentfulCallToAction {
                        ...CallToAction
                    }
                    ... on ContentfulPage {
                        location: slug
                    }
                    ... on ContentfulPageLanding {
                        location: slug
                    }
                }
                markets {
                    ...MarketGeneral
                }
            }
        }
        markets {
            ...MarketGeneral
        }
    }
`;
